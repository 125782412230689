import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'
import { SeFeMenuOptions } from 'se-fe-menu'
import { CredentialSet, PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'
import { EligibilityService } from './eligibility.service'

export interface MembershipListItem {
  chip: any
  credentialSet: CredentialSet
  eligibilityStatus: string
  menuOptions?: SeFeMenuOptions
  personaEligibility: PersonaEligibility
  subtitle: string[]
  title: string
  triggerCredential: any
  url?: string
  validityDates: string
  validity: string
}

const STATUS_COLOR_MAP = {
  eligible: 'done',
  ineligible: 'caution',
  incomplete_complete: 'caution',
  incomplete: 'caution',
  requested: 'caution',
  expired: 'critical',
  canceled: 'critical',
  suspended: 'critical',
  upcoming: 'on'
}

const STATUS_NAME_MAP = {
  eligible: 'Eligible',
  ineligible: 'Ineligible',
  incomplete: 'Ineligible',
  requested: 'Ineligible',
  expired: 'Expired',
  canceled: 'Canceled',
  suspended: 'Suspended',
  upcoming: 'Starts'
}

const STATUS_SORT_WEIGHTS = {
  ineligible: 1,
  incomplete: 1,
  requested: 1,
  eligible: 2,
  upcoming: 3,
  expired: 4,
  suspended: 5,
  canceled: 6
}

@Injectable({
  providedIn: 'root',
})
export class MembershipListItemsService {

  constructor(
    private eligibilityService: EligibilityService
  ) { }

  public async getListItems(personaIds: (string | number)[]): Promise<MembershipListItem[]> {
    const personaEligibilities = await this.getPersonaEligibilities(personaIds.filter((id) => id))
    const listItems = personaEligibilities.flatMap((pe: PersonaEligibility) => pe.credential_sets.map((credentialSet: CredentialSet) => {
        const triggerCredential = pe.eligibility_credentials.find((ec: any) =>
          credentialSet.trigger_credential_value === ec.credential_value
        ) as any
        if (!triggerCredential) {
          return
        }
        const { timezone } = (pe as any).eligibility_rule_set.boss_organization
        const effectiveAt = this.dateFormat(triggerCredential.effective_at, timezone)
        const expiresAt = this.dateFormat(triggerCredential.expires_at, timezone)
        const eligibilityStatus = this.calculateEligibilityStatus(credentialSet, triggerCredential)

        return {
          chip: this.getChip(eligibilityStatus, credentialSet, timezone),
          credentialSet,
          eligibilityStatus,
          personaEligibility: pe,
          subtitle: this.subtitle(pe, credentialSet),
          title: pe.eligibility_rule_set_name,
          triggerCredential,
          validity: this.validity(pe, triggerCredential, effectiveAt, expiresAt),
          validityDates: this.validityDates(effectiveAt, expiresAt)
        }
      })).filter((pe) => pe)
    return this.sortEligibilityStatus(listItems)
  }

  public sortEligibilityStatus(listItems: MembershipListItem[]): MembershipListItem[] {
    return listItems.sort((first, second) => {
      const firstWeight = STATUS_SORT_WEIGHTS[first.eligibilityStatus]
      const secondWeight = STATUS_SORT_WEIGHTS[second.eligibilityStatus]
      if (firstWeight > secondWeight) return 1
      if (firstWeight < secondWeight) return -1

      const firstEffectiveAt = first.triggerCredential.effective_at
      const secondEffectiveAt = second.triggerCredential.effective_at
      if (firstEffectiveAt > secondEffectiveAt) return 1
      if (firstEffectiveAt < secondEffectiveAt) return -1

      return 0
    })
  }

  /**
   * Gets persona eligibilities and then filters out any parent memberships by persona.
   * Ex. Region membership for persona A linked to an NGB membership for persona A we only display the region membership
   */
  private async getPersonaEligibilities(personaIds: (string | number)[]): Promise<PersonaEligibility[]> {
    if (!personaIds || !personaIds.length) {
      return []
    }
    const personaEligibilities = await this.eligibilityService.findAll(personaIds, 1000).toPromise()
    const linkedEligibilityRuleSetIds = this.getLinkedEligibilityRuleSetIds(personaEligibilities)
    return personaEligibilities.filter(pe => !linkedEligibilityRuleSetIds[pe.persona_id].includes(pe.eligibility_rule_set_id))
  }

  /**
   * Loops through and finds any linked membership_definition rules and grabs the linked_eligibility_rule_set_id
   * and stores it by persona id. This is used to filter out a persona's parent memberships from displaying.
   */
  private getLinkedEligibilityRuleSetIds(personaEligibilities: PersonaEligibility[]): { [key: number]: string[]} {
    const linkedEligibilityRuleSetIds = { }
    personaEligibilities.forEach((pe: any) => {
      let linkedRuleSetIds = pe.eligibility_rule_set.eligibility_rules
        .filter((er: any) => er.linked_eligibility_rule_set_id && er.originator_type === 'membership_definition')
        .map((er: any) => er.linked_eligibility_rule_set_id)
      const personaId = pe.persona_id
      linkedRuleSetIds += linkedEligibilityRuleSetIds[personaId] || []
      linkedEligibilityRuleSetIds[personaId] = linkedRuleSetIds
    })
    return linkedEligibilityRuleSetIds
  }

  private getChip(eligibilityStatus: string, credentialSet: CredentialSet, timezone: string): any {
    const effectiveAt = this.dateFormat(credentialSet.effective_at, timezone)
    return {
      color: STATUS_COLOR_MAP[eligibilityStatus],
      title: this.label(eligibilityStatus, effectiveAt)
    }
  }

  private subtitle(personaEligibility: PersonaEligibility, credentialSet: CredentialSet): string[] {
    const subtitle  = personaEligibility.affiliation_path?.find((affiliation) =>
      affiliation.organization_id === credentialSet.organization_id
    )?.organization_name
   return subtitle ? [subtitle] : []
  }

  private validity(personaEligibility: any, triggerCredential: any, effectiveAt: string, expiresAt: string): string {
    switch (triggerCredential.eligibility_status) {
      case 'upcoming':
      case 'valid':
        return this.validRangeString(effectiveAt, expiresAt)
      case 'expired':
        return this.expiredString(expiresAt)
      case 'requested': {
        const orgName = triggerCredential.linked_eligibility_rule_set?.boss_organization.name ||
          personaEligibility.eligibility_rule_set.boss_organization.name
        return `Membership currently awaiting review from ${orgName}`
      }
      default:
        return null
    }
  }

  private validRangeString(effectiveAt: string, expiresAt: string): string {
    if (effectiveAt && expiresAt) {
      return `Valid From ${effectiveAt} - ${expiresAt}`
    }
    return `Valid On ${effectiveAt}`
  }

  private expiredString(expiresAt: string): string {
    return expiresAt ? `Expired ${expiresAt}` : null
  }

  private label(eligibilityStatus: string, effectiveAt: string): string {
    const value: string[] = [STATUS_NAME_MAP[eligibilityStatus]]
    if (eligibilityStatus === 'upcoming') { value.push(effectiveAt) }
    return value.join(' ')
  }

  private calculateEligibilityStatus(credentialSet: CredentialSet, triggerCredential: any): string {
    let eligibilityStatus = credentialSet.eligibility_status.toLowerCase()
    if (eligibilityStatus === 'expired' && triggerCredential.eligibility_status !== 'expired') {
      eligibilityStatus = 'incomplete'
    }
    return eligibilityStatus
  }

  private validityDates(effectiveAt: string, expiresAt: string): string {
    if (!effectiveAt) {
      return '--'
    }
    return expiresAt ? `${effectiveAt} - ${expiresAt}` : effectiveAt
  }

  private dateFormat(date: string, zone: string): string {
    const dateTime = DateTime.fromISO(date, { zone })
    if (dateTime.isValid) {
      return dateTime.toFormat('MMM d, yyyy')
    }
  }

}
