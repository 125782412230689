console.log('ENV = STAGING')

export const environment = {
  apiUrl: 'https://api.stage.ngin-staging.com/',
  app: 'https://app.stage.ngin-staging.com/',
  // The following is used by SeFeAttachmentService
  assetService: 'https://attachments.se-stage-assets.com/',
  baseUrl: 'https://people-and-orgs.ui.stage.ngin-staging.com',
  bossUrl: 'https://boss.ui.stage.ngin-staging.com',
  clientId: '4910b33e9c273b6426cc050b8a3de315',
  eligibilityListUi: 'https://eligibility-list.ui.stage.ngin-staging.com',
  hqPath: 'https://org.stage.ngin-staging.com/org',
  local: false,
  membershipService: 'https://membership-service.sestage.us',
  mySE: 'https://my.stage.ngin-staging.com/user',
  name: 'staging',
  nbcUniversal: 'https://stage.nbcuniversal.com',
  nginUserDashboard: 'https://login.stage.ngin-staging.com/',
  recaptchaV2SiteKey: '6LcIxy4UAAAAANuWSG9dSmM1UhZNoWYGU0tdrmYF',
  production: false,
  seBar: 'https://se-bar.stage.ngin-staging.com/se-bar.js',
  seFeAngularServices: 'https://se-fe-angular-services.ui.stage.ngin-staging.com',
  sePayments: 'https://se-payments.stage.ngin-staging.com/',
  siteRedirect: 'https://admin.stage.ngin-staging.com/site_admin/org_redirect',
  sportAdminElements: 'https://sport-admin-elements.ui.stage.ngin-staging.com',
  staging: true,
  uiThemes: 'https://s3.amazonaws.com/sportngin-snap-staging/ui_themes',
  user: 'https://user.sestage.us/',
  webCal: 'webcal://ical.stage.ngin-staging.com/v3/calendar/ical',
  orderHistory: 'https://order-history.ui.stage.ngin-staging.com',
  returnUrls: {
    memberships: 'https://memberships.sestage.us/'
  },
  domainEnvironments: [
    {
      domain: 'ngin-staging.com',
      default: true,
      urls: {
        api: 'https://api.stage.ngin-staging.com',
        userService: 'https://user.stage.ngin-staging.com/',
      }
    },
    {
      domain: 'sestage.us',
      default: false,
      urls: {
        api: 'https://se-api.sestage.us',
        userService: 'https://user.sestage.us/',
      }
    }
  ],
  // The following is for integration with se-fe-auth-support
  domainSpecificUrls: [
    {
      domain: 'stage.ngin-staging.com',
      default: true,
      api: 'https://api.stage.ngin-staging.com/',
      user: 'https://user.stage.ngin-staging.com/'
    },
    {
      domain: 'sestage.us',
      default: false,
      api: 'https://se-api.sestage.us/',
      user: 'https://user.sestage.us/'
    }
  ]
}
