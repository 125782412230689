import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

export interface UserProfileDuplicate {
  persona_one_id: number
  persona_one_first_name: string
  persona_one_last_name: string
  persona_two_id: number
  persona_two_first_name: string
  persona_two_last_name: string
  dupe_type: string
}

@Injectable({
  providedIn: 'root'
})
export class HouseholdProfileDuplicatesService {
  public endpoint = 'v3/households/profile_duplicates'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public mine(): Observable<UserProfileDuplicate[]> {
    return this.api.get<UserProfileDuplicate[]>(`${this.endpoint}/mine`, { })
      .pipe(
        first(),
        map(response => response.result)
      )
  }
}
