<div *ngIf="ready">
  <form [formGroup]="form" *ngIf="ready">
    <se-fe-list>
      <se-fe-list-item *ngIf="addableChildren?.length">
        <div class="guardian-list-header">
          <se-fe-checkbox
            class="guardian-list-header"
            formControlName="selectAll"
            [seFeDataOptions]="checkboxOptions.selectAll">
          </se-fe-checkbox>
          <div class="guardian-access">
            Access Levels
            <button type="button" seFePopoverTrigger="parentGrantAccessPopover" class="guardian-popover">
              <se-fe-icon
                seFeDataName="info_circle"
                seFeDataSize="500"
                seFeDataColor="default">
              </se-fe-icon>
            </button>
            <se-fe-popover seFeDataName="parentGrantAccessPopover" [seFeDataFixedPosition]="true">
              Learn more about&ensp;
              <a
                href="https://help.sportsengine.com/en/articles/9265109"
                class="guardian-popover-link"
                target="_blank">
                access levels.
              </a>
            </se-fe-popover>
          </div>
        </div>
      </se-fe-list-item>
      <se-fe-list-item *ngFor="let p of addableChildren">
        <div class="guardian-list-item">
          <div *ngIf="p.disabled" class="guardian-checkbox" [seFeTooltipTrigger]="p.id">
            <se-fe-checkbox [formControlName]="p.id.toString()" [seFeDataHiddenLabel]="true"
              [seFeDataDisabled]="true" [seFeDataOptions]="checkboxOptions[p.id]"
              style="margin-right: 12px;">
            </se-fe-checkbox>
            <se-po-persona-avatar class="disabled" [persona]="p.persona" headerSubtitle="">
            </se-po-persona-avatar>
            <se-fe-tooltip *ngIf="p.atGuardianLimit && !p.invitePending" [seFeDataName]="p.id">
              {{ p.persona.first_name }} has reached the limit for guardianship access.
            </se-fe-tooltip>
            <se-fe-tooltip *ngIf="p.invitePending" [seFeDataName]="p.id">
              This person has already been granted access to {{ p.persona.first_name }}
            </se-fe-tooltip>
            <div class="guardian-access">
              <se-fe-chip [seFeDataText]="p.menuButtonText" [seFeDataSelected]="p.chipSelected" [seFeDataDisabled]="p.disabled"></se-fe-chip>
            </div>
          </div>
          <div *ngIf="!p.disabled" class="guardian-checkbox">
            <se-fe-checkbox [formControlName]="p.id.toString()" [seFeDataHiddenLabel]="true"
              [seFeDataOptions]="checkboxOptions[p.id]"
              style="margin-right: 12px;">
            </se-fe-checkbox>
            <se-po-persona-avatar [persona]="p.persona" headerSubtitle="">
            </se-po-persona-avatar>
            <div class="guardian-access">
              <se-fe-chip [ngClass] = "{ 'guardian-access-error': hasAccessError(p.id) }"
              [seFeDataText]="p.menuButtonText"
              [seFeDataSelected]="p.chipSelected"
              [seFeMenuTrigger]="p.permissionMenuOpts.name"
              [attr.aria-describedby]="'required-error' + p.id.toString()"
              ></se-fe-chip>
            </div>
          </div>
        </div>
      </se-fe-list-item>
    </se-fe-list>
    <se-fe-form-control-errors
      *ngIf="form.touched && errors.length"
      [seFeDataErrors]="errors"
      seFeDataId="errorId"
      [seFeDataMessages]="{
        selectionRequired: 'You must select a person',
        required: 'Access level is required'
      }"
    >
  </se-fe-form-control-errors>
  </form>
  <div *ngFor="let p of addableChildren">
    <se-fe-menu *ngIf="!p.disabled" [seFeDataOptions]="p.permissionMenuOpts"></se-fe-menu>
  </div>
</div>
