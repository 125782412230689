import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HouseholdSidePanelComponent } from '@se-po/se-my-sportsengine-feature-household-side-panel'
import { NewPersonModalComponent } from '@se-po/se-my-sportsengine-feature-new-person-modal'
import {
  AnalyticsService,
  HouseholdProfilesService,
  HouseholdRelatedGuardiansService,
  UserPreferenceService
} from '@se-po/shared-data-access-services'
import { EditPersonaModalComponent } from '@se-po/shared-feature-edit-persona-modal'
import { MergePersonaModalComponent } from '@se-po/shared-feature-merge-persona-modal'
import { RemovePersonaModalComponent } from '@se-po/shared-feature-remove-persona-modal'
import { map, Observable, Subscription, tap, zip } from 'rxjs'
import { Guardianship, PersonaAccount, RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

@Component({
  selector: 'se-po-household',
  templateUrl: './household.component.html',
  styleUrls: ['./household.component.scss'],
})
export class HouseholdComponent implements OnInit, OnDestroy {
  @ViewChild('newPersonModal') public newPersonModal: NewPersonModalComponent
  @ViewChild('householdSidePanel') public householdSidePanel: HouseholdSidePanelComponent
  @ViewChild('editPersonaModal') public editPersonaModal: EditPersonaModalComponent
  @ViewChild('mergePersonaModal') public mergePersonaModal: MergePersonaModalComponent
  @ViewChild('removePersonaModal') public removePersonaModal: RemovePersonaModalComponent

  public adultProfiles: UserProfile[]
  public allGuardians: RelatedGuardiansResult[]
  public childProfiles: UserProfile[]
  public managerGuardians: RelatedGuardiansResult[]
  public mySeVersion: number
  public ready = false
  public selfProfile: UserProfile

  private _profiles: UserProfile[]
  private _selectedPersonaId: number = null
  private _subscriptions: Subscription[] = []

  constructor(
    private userPreferenceService: UserPreferenceService,
    private householdProfilesService: HouseholdProfilesService,
    private householdRelatedGuardiansService: HouseholdRelatedGuardiansService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) { }

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  public get selectedPersonaId(): number {
    return this._selectedPersonaId
  }

  public set profiles(profiles: UserProfile[]) {
    this._profiles = profiles
    this.selfProfile = profiles.find(p => p.relationship === 'self')
    this.childProfiles = profiles.filter(p => p.relationship === 'other' && !!p.persona.age && p.persona.age < 18)
    this.adultProfiles = profiles.filter(p => p.relationship === 'other' && (!p.persona.age || p.persona.age >= 18))
  }

  public set selectedPersonaId(selectedPersonaId: number) {
    if (selectedPersonaId !== this.selectedPersonaId) {
      this._selectedPersonaId = selectedPersonaId
      this.setIdParam(this.selectedPersonaId || null)
    }
  }

  public async ngOnInit(): Promise<void> {
    let personaId: number
    if (this.route.snapshot.queryParams.id) {
      personaId = Number(this.route.snapshot.queryParams.id)
    }
    this.getHousehold(personaId)
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public getHousehold(id?: number): void {
    const personaId = id || this.selectedPersonaId
    this._subscriptions.push(zip(this.getProfiles(), this.getGuardians())
      .subscribe(([profiles, guardians]: [UserProfile[], RelatedGuardiansResult[]]) => {
        this.profiles = profiles
        this.managerGuardians = guardians
        if (personaId) {
          const profile = this.profiles.find(p => p.persona.id === personaId)
            || this.managerGuardians.find(g => g.user.self_persona.id === personaId)
          if (profile) {
            this.selectedPersonaId = personaId
          }
          else if (this.selectedPersonaId) {
            this.selectedPersonaId = null
          }
        }
        this.ready = true
      }
    ))
  }

  public getProfiles(): Observable<UserProfile[]> {
    return this.householdProfilesService.findMyProfiles()
  }

  public getGuardians(): Observable<RelatedGuardiansResult[]> {
    return this.householdRelatedGuardiansService.mine().pipe(
      tap(guardians => this.allGuardians = guardians),
      // we only want guardians with manager access in the household display
      map(guardians => guardians.filter(g => (g.profiles || []).some(p => p.access === 'manager')))
    )
  }


  public onChosen(p: UserProfile | PersonaAccount): void {
    const personaId = 'user' in p ? p.user.self_persona.id : p?.persona?.id
    if (this.selectedPersonaId !== personaId) {
      this.selectedPersonaId = personaId
    }
    else {
      this.selectedPersonaId = null
    }
  }

  public onEdit(p: UserProfile): void {
    this.analyticsService.seEvent('Household.EditProfileClick', 8)
    this.editPersonaModal.open(p)
  }

  public onMerge([keepProfile, mergeProfile]: UserProfile[], description: string): void {
    this.analyticsService.seEvent('Household.MergeProfileClick', 8)
    this.mergePersonaModal.open(keepProfile, mergeProfile, description)
  }

  public onRemove(p: UserProfile): void {
    this.analyticsService.seEvent('Household.RemoveProfileClick', 8)
    this.removePersonaModal.open(p)
  }

  public onPersonaEdit(personaId: number): void {
    // trigger getting an updated self across mySE if the self profile image was updated
    const profile = this.profiles.find(p => p.persona.id === personaId)
    if (profile.relationship === 'self' && profile.access === 'owner') {
      this.householdProfilesService.loadSelfPersona()
    }
    this.getHousehold()
  }

  public onPersonaRemove(removedProfile: UserProfile): void {
    this.profiles = this.profiles.filter(p => p.id !== removedProfile.id)
    this._subscriptions.push(this.getGuardians().subscribe(guardians => this.managerGuardians = guardians))
    if (this.selectedPersonaId && this.selectedPersonaId === removedProfile.persona.id) {
      this.selectedPersonaId = null
    }
  }

  public onGuardianUpdate(guardianship: Guardianship): void {
    let updateHousehold$: Observable<any>
    if (guardianship.user.self_persona.id === this.selfProfile.persona.id) {
      updateHousehold$ = this.getProfiles().pipe(tap(profiles => this.profiles = profiles))
    } else {
      updateHousehold$ = this.getGuardians().pipe(tap(guardians => this.managerGuardians = guardians))
    }
    this._subscriptions.push(updateHousehold$.subscribe(_ => {
      if (this.selectedPersonaId) {
        const profile = this.profiles.find(p => p.persona.id === this.selectedPersonaId)
          || this.managerGuardians.find(g => g.user.self_persona.id === this.selectedPersonaId)
        if (!profile) this.selectedPersonaId = null
      }
    }))
  }

  private setIdParam(id: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { id },
      queryParamsHandling: 'merge'
    })
  }
}
