import { Injectable } from '@angular/core'
import { HouseholdPersonaDemographicsUpdateParams, HouseholdPersonaUpdateParams } from '@se-po/shared-data-access-models'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { CentralServiceResources as cs } from 'se-resource-types'
import { ApiResponse } from 'se-resource-types/dist/lib/common'

@Injectable({
  providedIn: 'root'
})
export class HouseholdPersonasService {
  public endpoint = 'v3/households/personas'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public findPersonaAccountsResponse(id: string | number): Observable<ApiResponse<cs.Households.PersonaAccountsResult>> {
    return this.api.get<cs.Households.PersonaAccountsResult>(`${this.endpoint}/${id}/accounts`).pipe(
      first()
    )
  }

  public findPersonaAccounts(id: string | number): Observable<cs.Households.PersonaAccount[]> {
    return this.findPersonaAccountsResponse(id).pipe(
      first(),
      map(response => response.result.accounts)
    )
  }

  public findPersonaAccountsOrganization(
    id: string | number, org_id: string | number, params={}
  ): Observable<cs.Households.PersonaAccountsResult> {
    return this.api.get<cs.Households.PersonaAccountsResult>(
      `${this.endpoint}/${id}/accounts/${org_id}`,
      { params }
    ).pipe(
      first(),
      map(response => response.result)
    )
  }

  public findPersonaGuardianInvites(id: string | number): Observable<cs.Households.PersonaGuardianInvite[]> {
    return this.api.get<cs.Households.PersonaGuardianInvitesResult>(`${this.endpoint}/${id}/guardian_invites`).pipe(
      first(),
      map(response => response.result.guardian_invites)
    )
  }

  public findPersonaDemographics(id: string | number): Observable<cs.Households.PersonaDemographicsResult> {
    const url = `${this.endpoint}/${id}/demographics`
    return this.api.get<cs.Households.PersonaDemographicsResult>(url).pipe(
      first(),
      map(response => response.result)
    )
  }

  public updatePersonaDemographics(
    id: string | number,
    data: HouseholdPersonaDemographicsUpdateParams
  ): Observable<cs.Households.PersonaDemographicsResult> {
    const url = `${this.endpoint}/${id}/demographics`
    return this.api.put<cs.Households.PersonaDemographicsResult>(url, data)
      .pipe(
        first(),
        map(response => response.result)
      )
  }

  public updatePersona(id: string | number, data: HouseholdPersonaUpdateParams): Observable<cs.Profiles.Persona> {
    const url = `${this.endpoint}/${id}`
    return this.api.put<any>(url, data)
      .pipe(
        first(),
        map(response => response.result)
      )
  }
}
