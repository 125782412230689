<div>
  <se-fe-accordion
    *ngIf="hasAllRsvps"
    [seFeDataTitle]="rsvpAccordionTitle"
    [seFeDataExpanded]="rsvpListOpen"
    (seFeExpandedChange)="toggleAccordion()">
    <div *ngIf="!rsvpListOpen" seFeAccordionHeaderActions class="rsvpAccordionHeader">
      <div *ngFor="let rsvp of headerAvatars">
        <se-fe-avatar *ngIf="rsvp.response !=='no_response'"
          seFeDataType="persona"
          [seFeDataIdentity]="rsvp.identity"
          seFeDataSize="200"
          [seFeDataBorder]= true
          [seFeDataStatusIcon]="rsvpIcons[rsvp.id].icon"
          [seFeDataStatusColor]="rsvpIcons[rsvp.id].color"
          class="avatarGroup">
        </se-fe-avatar>
        <se-fe-avatar *ngIf="rsvp.response === 'no_response'"
          seFeDataType="persona"
          [seFeDataIdentity]="rsvp.identity"
          seFeDataSize="200"
          [seFeDataBorder]= true
          class="avatarGroup">
        </se-fe-avatar>
      </div>
      <se-fe-avatar *ngIf="additionalAvatars"
        seFeDataType="general"
        [seFeDataIdentity]="additionalAvatars"
        seFeDataSize="200"
        [seFeDataBorder]= true
        class="avatarGroup">
      </se-fe-avatar>
    </div>
    <div *seFeAccordionContent>
      <se-fe-list>
        <se-fe-list-item *ngFor="let rsvp of rsvps" [rsvp]="rsvp">
          <app-rsvp-list-item [rsvp]="rsvp" (onRsvp)="emitEvent($event)"></app-rsvp-list-item>
        </se-fe-list-item>
      </se-fe-list>
    </div>
  </se-fe-accordion>

  <div *ngIf="!hasAllRsvps && rsvps.length">
    <se-fe-list>
      <se-fe-list-item>
        <se-fe-header size="600" seFeDataTitle="Are You Going?"></se-fe-header>
      </se-fe-list-item>
      <se-fe-list-item *ngFor="let rsvp of rsvps">
        <app-rsvp-list-item [rsvp]="rsvp" (onRsvp)="emitEvent($event)"></app-rsvp-list-item>
      </se-fe-list-item>
    </se-fe-list>
  </div>
</div>
