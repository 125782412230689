<form [formGroup]="form" *ngIf="form">
  <se-fe-form-field *ngIf="loaded"
    [seFeDataConfig]="{ label: seFeDataLabel, errorMessages: errorMessages }">
    <se-fe-datepicker class="dob-datepicker"
      [formControlName]="ngControl.name"
      [seFeDataSuffix]="age"
      [seFeDataExcludeDates]="datepickerLimit"
      [seFeDataYearRange]="{ future: 0, past: 100 }"
      seFeDataSuffixSize=3
      [seFeDataComfy]="true">
    </se-fe-datepicker>
  </se-fe-form-field>
</form>
